import dayjs from 'dayjs';
import i18n, { KeyPrefix } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import resourcesToBackend from 'i18next-resources-to-backend';
import { useEffect } from 'react';
import {
  FallbackNs,
  UseTranslationOptions,
  initReactI18next,
  useTranslation as useTranslationOrg,
} from 'react-i18next';
import { $Tuple } from 'react-i18next/helpers';
import { defaultNS, getOptions } from './settings';

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(
    resourcesToBackend(
      (language: string) =>
        import(`./locales/${language}/translation.json`)
    )
  )
  .init({
    ...getOptions(),
    lng: undefined,
    detection: {
      order: ['path', 'htmlTag', 'cookie', 'navigator'],
    },
    preload: [],
  });

type Ns = string | $Tuple<string> | undefined;
type KPrefix = KeyPrefix<FallbackNs<Ns>>;

export function useTranslate(
  lng: string,
  ns: Ns = defaultNS,
  options: UseTranslationOptions<KPrefix> = {}
) {
  const ret = useTranslationOrg(ns, options);
  const { i18n } = ret;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (i18n.resolvedLanguage === lng) return;
    i18n.changeLanguage(lng);
    dayjs.locale(lng);
  }, [lng, i18n]);
  return ret;
}
