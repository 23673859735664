import { combineReducers, configureStore } from '@reduxjs/toolkit';
import setApiErrorReducer from './app/setApiError/setApiError.slice';
import setAppMountedReducer from './app/setAppMounted/setAppMounted.slice';
import setMeReducer from './app/setMe/setMe.slice';
import setPathnameReducer from './app/setPathname/setPathname.slice';
import sendOtpReducer from './auth/sendOtp/sendOtp.slice';
import verifyOtpReducer from './auth/verifyOtp/verifyOtp.slice';
import wheelHashReducer from './auth/wheelHash/wheelHash.slice';
import codeUseReducer from './code/codeUse/codeUse.slice';
import sendCodeOtpReducer from './code/sendCodeOtp/sendCodeOtp.slice';
import verifyCodeOtpReducer from './code/verifyCodeOtp/verifyCodeOtp.slice';
import setRemoteConfigReducer from './firebase/setRemoteConfig/setRemoteConfig.slice';
import getRandomQuestionReducer from './question/getRandomQuestion/getRandomQuestion.slice';
import answerTryReducer from './user/answerTry/answerTry.slice';
import sendAnswerReducer from './user/sendAnswer/sendAnswer.slice';

const appReducer = combineReducers({
  setApiError: setApiErrorReducer,
  setAppMounted: setAppMountedReducer,
  setMe: setMeReducer,
  setPathname: setPathnameReducer,
});

const authReducer = combineReducers({
  sendOtp: sendOtpReducer,
  verifyOtp: verifyOtpReducer,
  wheelHash: wheelHashReducer,
});

const codeReducer = combineReducers({
  codeUse: codeUseReducer,
  sendCodeOtp: sendCodeOtpReducer,
  verifyCodeOtp: verifyCodeOtpReducer
});

const firebaseReducer = combineReducers({
  setRemoteConfig: setRemoteConfigReducer,
});

const questionReducer = combineReducers({
  getRandomQuestion: getRandomQuestionReducer,
});

const userReducer = combineReducers({
  sendAnswer: sendAnswerReducer,
  answerTry: answerTryReducer,
});

const store = configureStore({
  reducer: {
    app: appReducer,
    auth: authReducer,
    code: codeReducer,
    firebase: firebaseReducer,
    question: questionReducer,
    user: userReducer,
  },
});

export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
