import { OtpRequest } from '../../../models/requests/otp.request';
import axios from '../../axios';

export default async function sendOtpRequest(request: OtpRequest): Promise<void> {
  const response = await axios.post<void>(
    `${process.env.REACT_APP_BASE_URL}/auth/send-otp/anon`,
    request
  );
  return response.data;
}
