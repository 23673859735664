import { useCallback } from 'react';
import { AppRouteKey } from '../utilities/router';
import useLang from './useLang';
import { NavigateFunction, useNavigate } from 'react-router-dom';

interface Return {
  navigate: NavigateFunction;
  /**
   * uses next navigation push method and provide the url with lang.
   * @see provideUrl
   * @param pathName
   */
  push: (pathName?: AppRouteKey | string) => void;
  lang: string;
  current: string;
  /**
   * create the href string with lang at the beginning and href after that
   * @see useLang
   * @see AppRouteKey
   * @param href
   */
  provideUrl: (href?: string) => string;
}

/**
 * hook for handling navigation in Nevita App
 * provide lang with the urls
 */
const useRouterWithLang = (): Return => {
  const { lang, current } = useLang();
  const navigate = useNavigate();

  const provideUrl = useCallback(
    (pathName?: string) => `/${lang}${pathName}`.replace(/\/{2,}/g, `/`),
    [lang]
  );

  const push = useCallback(
    (pathName?: string) => {
      navigate(provideUrl(pathName));
    },
    // eslint-disable-next-line
    [lang, provideUrl]
  );

  return { navigate, push, lang, current, provideUrl };
};

export default useRouterWithLang;
