import { AnswerTryRequest } from '../../../models/requests/answerTry.request';
import axios from '../../axios';

export default async function answerTryRequest(
  request: AnswerTryRequest
): Promise<void> {
  const response = await axios.post<void>(
    `${process.env.REACT_APP_BASE_URL}/answers/try`,
    request
  );
  return response.data;
}
