import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ErrorDto } from '../../../models/dtos/error.dto';
import { Errors } from '../../errors';
import verifyCodeOtpRequest from './verifyCodeOtp.repository';
import { OtpRequest } from '../../../models/requests/otp.request';
import { OtpDto } from '../../../models/dtos/otp.dto';
import { SessionStorage } from '../../../utilities/sessionStorage';

type InitialState = {
  loading: boolean;
  data?: OtpDto;
  error?: ErrorDto;
};
const initialState: InitialState = {
  loading: false,
  data: undefined,
  error: undefined,
};

export const verifyCodeOtp = createAsyncThunk(
  'verifyCodeOtp',
  async (otpRequest: OtpRequest) => {
    const response = await verifyCodeOtpRequest(otpRequest);
    SessionStorage.set(SessionStorage.token, response.token);
    return response;
  }
);
const verifyCodeOtpSlice = createSlice({
  name: 'verifyCodeOtp',
  initialState,
  reducers: {
    verifyCodeOtpReset: (state) => {
      state.loading = false;
      state.data = undefined;
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(verifyCodeOtp.pending, (state) => {
      state.loading = true;
      state.data = undefined;
      state.error = undefined;
    });
    builder.addCase(verifyCodeOtp.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(verifyCodeOtp.rejected, (state, action) => {
      state.loading = false;
      state.error = Errors.getErrorDtoFromApiError(action.error);
    });
  },
});

export default verifyCodeOtpSlice.reducer;
export const { verifyCodeOtpReset } = verifyCodeOtpSlice.actions;
