import { getAnalytics, logEvent } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import {
  fetchAndActivate,
  getRemoteConfig,
  getValue,
} from 'firebase/remote-config';
import i18next from 'i18next';
import { isEmpty } from 'lodash';
import Helpers from '../../utilities/helpers';

export default class FirebaseService {
  private static readonly config = {
    apiKey: `${process.env.REACT_APP_FIREBASE_API_KEY}`,
    authDomain: `${process.env.REACT_APP_FIREBASE_AUTH_DOMAIN}`,
    databaseURL: `${process.env.REACT_APP_FIREBASE_DATABASE_URL}`,
    projectId: `${process.env.REACT_APP_FIREBASE_PROJECT_ID}`,
    storageBucket: `${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}`,
    messagingSenderId: `${process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID}`,
    appId: `${process.env.REACT_APP_FIREBASE_APP_ID}`,
    measurementId: `${process.env.REACT_APP_FIREBASE_MEASUREMENT_ID}`,
  };

  private static readonly app = initializeApp(FirebaseService.config);
  private static readonly remoteConfig = getRemoteConfig(FirebaseService.app);
  private static readonly analytics = getAnalytics(FirebaseService.app);
  private static readonly separator = '|';

  constructor() {
    FirebaseService.remoteConfig.defaultConfig = {
      test_key: 'Firebase test',
    };
  }

  public async fetchAndActivateRemoteConfig(): Promise<void> {
    await fetchAndActivate(FirebaseService.remoteConfig);
  }

  public static getValue(key: string): string {
    const value = getValue(FirebaseService.remoteConfig, key).asString();
    return isEmpty(value) ? key : value;
  }

  public static getLangValue(key: string): string {
    const value = FirebaseService.getValue(key);
    if (value) {
      try {
        const langMap = JSON.parse(value);
        const lang = i18next.language;
        return langMap[lang] ?? langMap["en"] ?? value;
      } catch (e) {
        return value;
      }
    }
    return key;
  }

  public static logEvent(
    key: string,
    params?: { [key: string]: unknown }
  ): void {
    if (!Helpers.isEnvProd()) {
      console.log(`>> EVENT: ${key}`);
      return;
    }
    logEvent(FirebaseService.analytics, key, params);
  }

  public static getStringArray(key: string): string[] {
    const mergedString = FirebaseService.getLangValue(key);
    if (!mergedString) return [];
    return mergedString
      .split(FirebaseService.separator)
      .filter((s) => s.trim());
  }

  // KEYS
  public static readonly homepage_navbar_app_direct_button =
    'homepage_navbar_app_direct_button';
  public static readonly homepage_navbar_games_button =
    'homepage_navbar_games_button';
  public static readonly homepage_navbar_howto_button =
    'homepage_navbar_howto_button';
  public static readonly homepage_navbar_faq_button =
    'homepage_navbar_faq_button';
  public static readonly homepage_navbar_try_button =
    'homepage_navbar_try_button';
  public static readonly homepage_entry_header = 'homepage_entry_header';
  public static readonly homepage_entry_description =
    'homepage_entry_description';
  public static readonly homepage_entry_download_button =
    'homepage_entry_download_button';
  public static readonly homepage_entry_try_button =
    'homepage_entry_try_button';
  public static readonly homepage_entry_info = 'homepage_entry_info';
  public static readonly gameplay_header = 'gameplay_header';
  public static readonly gameplay_desc = 'gameplay_desc';
  public static readonly answer_button = 'answer_button';
  public static readonly games_header = 'games_header';
  public static readonly games_description = 'games_description';
  public static readonly games_images = 'games_images';
  public static readonly games_desc = 'games_desc';
  public static readonly howto_general_header = 'howto_general_header';
  public static readonly howto_general_description =
    'howto_general_description';
  public static readonly howto_images = 'howto_images';
  public static readonly faq_header = 'faq_header';
  public static readonly faq_description = 'faq_description';
  public static readonly faq_quesions = 'faq_quesions';
  public static readonly faq_answers = 'faq_answers';
  public static readonly data_1_header = 'data_1_header';
  public static readonly data_1_value = 'data_1_value';
  public static readonly data_2_header = 'data_2_header';
  public static readonly data_2_value = 'data_2_value';
  public static readonly data_3_header = 'data_3_header';
  public static readonly data_3_value = 'data_3_value';
  public static readonly app_direct_header = 'app_direct_header';
  public static readonly app_direct_description = 'app_direct_description';
  public static readonly app_direct_button = 'app_direct_button';
  public static readonly reward_header = 'reward_header';
  public static readonly reward_description = 'reward_description';
  public static readonly reward_big_reward_header = 'reward_big_reward_header';
  public static readonly reward_big_reward_description =
    'reward_big_reward_description';
  public static readonly reward_small_reward_header =
    'reward_small_reward_header';
  public static readonly reward_small_reward_description =
    'reward_small_reward_description';
  public static readonly reward_redeem_button = 'reward_redeem_button';
  public static readonly reward_play_button = 'reward_play_button';
  public static readonly reward_msisdn_description =
    'reward_msisdn_description';
  public static readonly reward_msisdn_placeholder =
    'reward_msisdn_placeholder';
  public static readonly reward_msisdn_terms_check =
    'reward_msisdn_terms_check';
  public static readonly reward_msisdn_info = 'reward_msisdn_info';
  public static readonly reward_msisdn_continue_button =
    'reward_msisdn_continue_button';
  public static readonly reward_msisdn_cancel_button =
    'reward_msisdn_cancel_button';
  public static readonly reward_msisdn_warning = 'reward_msisdn_warning';
  public static readonly reward_otp_description = 'reward_otp_description';
  public static readonly reward_otp_newcode_button =
    'reward_otp_newcode_button';
  public static readonly reward_otp_cancel_button = 'reward_otp_cancel_button';
  public static readonly reward_otp_timecode = 'reward_otp_timecode';
  public static readonly reward_otp_warning = 'reward_otp_warning';
  public static readonly reward_success_header = 'reward_success_header';
  public static readonly reward_success_app_button =
    'reward_success_app_button';
  public static readonly reward_success_home_button =
    'reward_success_home_button';
  public static readonly reward_failed_header = 'reward_failed_header';
  public static readonly reward_failed_app_button = 'reward_failed_app_button';
  public static readonly reward_failed_home_button =
    'reward_failed_home_button';

  public static readonly gameplay_wheel_header = 'gameplay_wheel_header';
  public static readonly gameplay_wheel_desc = 'gameplay_wheel_desc';
  public static readonly wheel_reward_success_desc = 'wheel_reward_success_desc';
  public static readonly kk_small_reward_success_desc = 'kk_small_reward_success_desc';
  public static readonly kk_big_reward_success_desc = 'kk_big_reward_success_desc';

  public static readonly code_header = 'code_header';
  public static readonly code_desc = 'code_desc';
  public static readonly code_button = 'code_button';
  public static readonly code_tool_tip_title = 'code_tool_tip_title';
  public static readonly code_tool_tip_desc = 'code_tool_tip_desc';
  public static readonly code_info = 'code_info';

  public static readonly home_usecode_success_header = 'home_usecode_success_header';
  public static readonly home_usecode_success_app_button = 'home_usecode_success_app_button';
  public static readonly home_usecode_success_home_button = 'home_usecode_success_home_button';
  public static readonly home_usecode_success_wallet_desc = 'home_usecode_success_wallet_desc';
  public static readonly home_usecode_success_token_desc = 'home_usecode_success_token_desc';
  public static readonly home_usecode_success_both_desc = 'home_usecode_success_both_desc';

  public static readonly home_usecode_invalid_code_header = 'home_usecode_invalid_code_header';
  public static readonly home_usecode_invalid_code_desc = 'home_usecode_invalid_code_desc';
  public static readonly home_usecode_invalid_code_home_button = 'home_usecode_invalid_code_home_button';

  // EVENTS
  public static readonly test_event = 'test_event';
}
