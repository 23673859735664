import { ErrorDto, ParsedErrorDto } from '../models/dtos/error.dto';

export class Errors {
  public static readonly errorGenericTitle: string = 'Error!';
  public static readonly errorGenericText: string = 'Something went wrong.';

  private static readonly errorUserAlreadyMessage: string =
    'user-already-registered';
  private static readonly answerTryError: string = 'try_answer_used';
  public static readonly wrongOtpError: string = 'wrong_login_otp';
  public static readonly invalid_code: string = 'invalid_code';
  public static readonly invalid_campaign: string = 'invalid_campaign';

  private static readonly ErrorGeneric: ErrorDto = {
    status: 500,
    path: '',
    message: Errors.errorGenericText,
    code: ''
  };

  private static getParsedErrorDtoFromApiError = (
    error: unknown
  ): ParsedErrorDto => {
    return JSON.parse(JSON.stringify(error)) as ParsedErrorDto;
  };

  public static getErrorDtoFromApiError = (error: any): ErrorDto => {
    if (!error.response || !error.response.data) {
      return Errors.ErrorGeneric;
    }
    return error.response.data;
  };

  public static isAuthError(error: unknown) {
    const { status } = this.getParsedErrorDtoFromApiError(error);
    return status === 401 || status === 403;
  }

  public static isMsisdn(error: any) {
    if (!error || !error.message) {
      return undefined;
    }
    return error.message === Errors.errorUserAlreadyMessage;
  }

  public static isOtpError(error: any) {
    const { status } = this.getParsedErrorDtoFromApiError(error);
    return status === 428;
  }

  public static isAnswerTryError(error: any) {
    const { message } = this.getParsedErrorDtoFromApiError(error);
    return message === this.answerTryError;
  }
}
