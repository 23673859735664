import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { fallbackLng, languages } from '../../i18n/settings';

const useLang = () => {
  const { pathname } = useLocation();

  const language = (): string => {
    const pathLang = pathname.split('/')[1];
    if (!languages.includes(pathLang)) {
      return fallbackLng;
    }

    return pathLang;
  };

  const currentPath = (): string => {
    const current = pathname.slice(3);
    return current;
  };

  const [lang, setLang] = useState(language());
  const [current, setCurrent] = useState(currentPath());

  useEffect(() => {
    setLang(language());
    setCurrent(currentPath());
    // eslint-disable-next-line
  }, [pathname]);

  return { lang, current };
};

export default useLang;
