import { OtpDto } from '../../../models/dtos/otp.dto';
import { OtpRequest } from '../../../models/requests/otp.request';
import axios from '../../axios';

export default async function verifyOtpRequest(
  request: OtpRequest
): Promise<OtpDto> {
  const response = await axios.post<OtpDto>(
    `${process.env.REACT_APP_BASE_URL}/auth/verify-otp/anon`,
    request
  );
  return response.data;
}
