import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ErrorDto } from '../../../models/dtos/error.dto';
import { Errors } from '../../errors';
import verifyOtpRequest from './verifyOtp.repository';
import { OtpRequest } from '../../../models/requests/otp.request';
import { OtpDto } from '../../../models/dtos/otp.dto';
import { SessionStorage } from '../../../utilities/sessionStorage';

type InitialState = {
  loading: boolean;
  data?: OtpDto;
  error?: ErrorDto;
};
const initialState: InitialState = {
  loading: false,
  data: undefined,
  error: undefined,
};

export const verifyOtp = createAsyncThunk(
  'verifyOtp',
  async (otpRequest: OtpRequest) => {
    const response = await verifyOtpRequest(otpRequest);
    SessionStorage.set(SessionStorage.token, response.token);
    return response;
  }
);
const verifyOtpSlice = createSlice({
  name: 'verifyOtp',
  initialState,
  reducers: {
    verifyOtpReset: (state) => {
      state.loading = false;
      state.data = undefined;
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(verifyOtp.pending, (state) => {
      state.loading = true;
      state.data = undefined;
      state.error = undefined;
    });
    builder.addCase(verifyOtp.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(verifyOtp.rejected, (state, action) => {
      state.loading = false;
      state.error = Errors.getErrorDtoFromApiError(action.error);
    });
  },
});

export default verifyOtpSlice.reducer;
export const { verifyOtpReset } = verifyOtpSlice.actions;
