import { QuestionDto } from '../../../models/dtos/question.dto';
import axios from '../../axios';

export default async function getRandomQuestionRequest(lang: string): Promise<QuestionDto> {
  const response = await axios.get<QuestionDto>(
    `${process.env.REACT_APP_BASE_URL}/questions/random/anon`, {
    params: {
      lang: lang.toUpperCase()
    }
  }
  );
  return response.data;
}
