import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ErrorDto } from '../../../models/dtos/error.dto';
import { AnswerTryRequest } from '../../../models/requests/answerTry.request';
import { Errors } from '../../errors';
import answerTryRequest from './answerTry.repository';

type InitialState = {
  loading: boolean;
  data?: boolean;
  error?: ErrorDto;
};
const initialState: InitialState = {
  loading: false,
  data: undefined,
  error: undefined,
};

export const answerTry = createAsyncThunk(
  'answerTry',
  async (request: AnswerTryRequest, { rejectWithValue }) => {
    try {
      await answerTryRequest(request);
      return true;
    } catch (error) {
      return rejectWithValue(Errors.getErrorDtoFromApiError(error));
    }
  }
);

const answerTrySlice = createSlice({
  name: 'answerTry',
  initialState,
  reducers: {
    answerTryReset: (state) => {
      state.loading = false;
      state.data = undefined;
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(answerTry.pending, (state) => {
      state.loading = true;
      state.data = undefined;
      state.error = undefined;
    });
    builder.addCase(
      answerTry.fulfilled,
      (state, action: PayloadAction<boolean>) => {
        state.loading = false;
        state.data = action.payload;
      }
    );
    builder.addCase(answerTry.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as any;
    });
  },
});

export default answerTrySlice.reducer;
export const { answerTryReset } = answerTrySlice.actions;
