import { CloseCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import { ReactNode } from 'react';
import { setApiError } from '../../../core/services/app/setApiError/setApiError.slice';
import { Errors } from '../../../core/services/errors';
import FirebaseService from '../../../core/services/firebase/firebaseService';
import { useAppDispatch, useAppSelector } from '../../../core/services/hooks';
import { UI } from '../../../core/utilities/ui';
import AppButton from '../../Buttons/AppButton/AppButton';
import FirebaseKey from '../../FirebaseKey/FirebaseKey';
import error from '../../../assets/images/error.png';
import './ApiErrorModal.scss';

function ApiErrorModal() {
  const apiError = useAppSelector((state) => state.app.setApiError.error);
  const dispatch = useAppDispatch();

  const isOpen = (): boolean => {
    return (
      !!apiError &&
      !Errors.isMsisdn(apiError) &&
      !Errors.isOtpError(apiError) &&
      !Errors.isAnswerTryError(apiError) &&
      apiError.code !== Errors.wrongOtpError
    );
  };

  const closeModal = (): void => {
    dispatch(setApiError());
  };

  const isInvalidCode = () => {
    return (
      apiError?.code === Errors.invalid_code ||
      apiError?.code === Errors.invalid_campaign
    );
  };

  const getIcon = (): ReactNode => {
    if (isInvalidCode()) {
      return <img className="image" src={error} alt="error" />;
    }
    return <CloseCircleOutlined className="icon" />;
  };

  const getTitle = (): ReactNode => {
    if (isInvalidCode()) {
      return (
        <FirebaseKey
          firebaseKey={FirebaseService.home_usecode_invalid_code_header}
        />
      );
    }
    return Errors.errorGenericTitle;
  };

  const getText = (): ReactNode => {
    if (isInvalidCode()) {
      return (
        <FirebaseKey
          firebaseKey={FirebaseService.home_usecode_invalid_code_desc}
        />
      );
    }
    return apiError?.message ?? Errors.errorGenericText;
  };

  const getButtonText = (): ReactNode => {
    if (isInvalidCode()) {
      return (
        <FirebaseKey
          firebaseKey={FirebaseService.home_usecode_invalid_code_home_button}
        />
      );
    }
    return 'OK';
  };

  return (
    <Modal
      className="api-error-modal"
      maskStyle={UI.modalMaskStyle()}
      open={isOpen()}
      closable={false}
      footer={null}
      centered
      destroyOnClose
      width={320}
    >
      {apiError && (
        <div className="content">
          {getIcon()}
          <span className="title">{getTitle()}</span>
          <span className="text">{getText()}</span>
          <AppButton block onClick={closeModal}>
            {getButtonText()}
          </AppButton>
        </div>
      )}
    </Modal>
  );
}

export default ApiErrorModal;
