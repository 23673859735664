import { ThemeConfig } from 'antd/es/config-provider';
import { CSSProperties } from 'react';

export class UI {
  static getTheme = (): ThemeConfig => {
    return {
      token: {
        fontFamily: '"Inter", -apple-system, sans-serif',
        colorPrimary: '#69E391',
        colorBgContainer: '#151718',
        colorBorder: '#3A3F42',
      },
    };
  };

  static modalMaskStyle(): CSSProperties {
    return {
      backdropFilter: 'blur(40px)',
      WebkitBackdropFilter: 'blur(40px)',
      backgroundColor: '#d5feff08',
    };
  }
}
