import axios from '../../axios';

export default async function sendCodeOtpRequest(msisdn: string): Promise<void> {
  const response = await axios.post<void>(
    `${process.env.REACT_APP_CODE_BASE_URL}/auth/send-otp`,
    {
      msisdn
    }
  );
  return response.data;
}
