import { CodeDto } from '../../../models/dtos/code.dto';
import axios from '../../axios';

export default async function codeUseRequest(code: string): Promise<CodeDto> {
  const response = await axios.post<CodeDto>(
    `${process.env.REACT_APP_CODE_BASE_URL}/campaigns/codes/consume`,
    { code }
  );
  return response.data;
}
