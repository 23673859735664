import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CodeDto } from '../../../models/dtos/code.dto';
import { ErrorDto } from '../../../models/dtos/error.dto';
import { Errors } from '../../errors';
import codeUseRequest from './codeUse.repository';

type InitialState = {
  loading: boolean;
  data?: CodeDto;
  error?: ErrorDto;
};
const initialState: InitialState = {
  loading: false,
  data: undefined,
  error: undefined,
};

export const codeUse = createAsyncThunk(
  'codeUse',
  async (code: string) => {
    return await codeUseRequest(code);
  }
);

const codeUseSlice = createSlice({
  name: 'codeUse',
  initialState,
  reducers: {
    codeUseReset: (state) => {
      state.loading = false;
      state.data = undefined;
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(codeUse.pending, (state) => {
      state.loading = true;
      state.data = undefined;
      state.error = undefined;
    });
    builder.addCase(
      codeUse.fulfilled,
      (state, action: PayloadAction<CodeDto>) => {
        state.loading = false;
        state.data = action.payload;
      }
    );
    builder.addCase(codeUse.rejected, (state, action) => {
      state.loading = false;
      state.error = Errors.getErrorDtoFromApiError(action.error);
    });
  },
});

export default codeUseSlice.reducer;
export const { codeUseReset } = codeUseSlice.actions;
