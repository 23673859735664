import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { OtpMsisdn } from '../../../models/custom/otpMsisdn';
import { ErrorDto } from '../../../models/dtos/error.dto';
import { Errors } from '../../errors';
import sendCodeOtpRequest from './sendCodeOtp.repository';

type InitialState = {
  loading: boolean;
  data?: OtpMsisdn;
  error?: ErrorDto;
};
const initialState: InitialState = {
  loading: false,
  data: undefined,
  error: undefined,
};

export const sendCodeOtp = createAsyncThunk(
  'sendCodeOtp',
  async (msisdn: string, { rejectWithValue }) => {
    try {
      await sendCodeOtpRequest(msisdn);
      return { msisdn };
    } catch (error) {
      return rejectWithValue(Errors.getErrorDtoFromApiError(error));
    }
  }
);
const sendCodeOtpSlice = createSlice({
  name: 'sendCodeOtp',
  initialState,
  reducers: {
    sendCodeOtpReset: (state) => {
      state.loading = false;
      state.data = undefined;
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(sendCodeOtp.pending, (state) => {
      state.loading = true;
      state.data = undefined;
      state.error = undefined;
    });
    builder.addCase(sendCodeOtp.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(sendCodeOtp.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as any;
    });
  },
});

export default sendCodeOtpSlice.reducer;
export const { sendCodeOtpReset } = sendCodeOtpSlice.actions;
