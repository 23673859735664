import { AnswerDto } from '../../../models/dtos/answer.dto';
import { AnswerRequest } from '../../../models/requests/answer.request';
import axios from '../../axios';

export default async function sendAnswerRequest(
  answerRequest: AnswerRequest
): Promise<AnswerDto> {
  const response = await axios.post<AnswerDto>(
    `${process.env.REACT_APP_BASE_URL}/answers/anon`,
    answerRequest
  );
  return response.data;
}
