import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AnswerDto } from '../../../models/dtos/answer.dto';
import { ErrorDto } from '../../../models/dtos/error.dto';
import { Errors } from '../../errors';
import sendAnswerRequest from './sendAnswer.repository';
import { AnswerRequest } from '../../../models/requests/answer.request';

type InitialState = {
  loading: boolean;
  data?: AnswerDto;
  error?: ErrorDto;
};
const initialState: InitialState = {
  loading: false,
  data: undefined,
  error: undefined,
};

export const sendAnswer = createAsyncThunk(
  'sendAnswer',
  (answerRequest: AnswerRequest) => {
    return sendAnswerRequest(answerRequest);
  }
);

const sendAnswerSlice = createSlice({
  name: 'sendAnswer',
  initialState,
  reducers: {
    sendAnswerReset: (state) => {
      state.loading = false;
      state.data = undefined;
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(sendAnswer.pending, (state) => {
      state.loading = true;
      state.data = undefined;
      state.error = undefined;
    });
    builder.addCase(
      sendAnswer.fulfilled,
      (state, action: PayloadAction<AnswerDto>) => {
        state.loading = false;
        state.data = action.payload;
      }
    );
    builder.addCase(sendAnswer.rejected, (state, action) => {
      state.loading = false;
      state.error = Errors.getErrorDtoFromApiError(action.error);
    });
  },
});

export default sendAnswerSlice.reducer;
export const { sendAnswerReset } = sendAnswerSlice.actions;
