import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { OtpMsisdn } from '../../../models/custom/otpMsisdn';
import { ErrorDto } from '../../../models/dtos/error.dto';
import { OtpRequest } from '../../../models/requests/otp.request';
import { Errors } from '../../errors';
import sendOtpRequest from './sendOtp.repository';

type InitialState = {
  loading: boolean;
  data?: OtpMsisdn;
  error?: ErrorDto;
};
const initialState: InitialState = {
  loading: false,
  data: undefined,
  error: undefined,
};

export const sendOtp = createAsyncThunk(
  'sendOtp',
  async (request: OtpRequest, { rejectWithValue }) => {
    try {
      await sendOtpRequest(request);
      return { msisdn: request.msisdn };
    } catch (error) {
      return rejectWithValue(Errors.getErrorDtoFromApiError(error));
    }
  }
);
const sendOtpSlice = createSlice({
  name: 'sendOtp',
  initialState,
  reducers: {
    sendOtpReset: (state) => {
      state.loading = false;
      state.data = undefined;
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(sendOtp.pending, (state) => {
      state.loading = true;
      state.data = undefined;
      state.error = undefined;
    });
    builder.addCase(sendOtp.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(sendOtp.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as any;
    });
  },
});

export default sendOtpSlice.reducer;
export const { sendOtpReset } = sendOtpSlice.actions;
