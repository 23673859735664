import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import axios from 'axios';
import { SessionStorage } from '../utilities/sessionStorage';
import { setApiError } from './app/setApiError/setApiError.slice';
import { Errors } from './errors';

let dispatch: ThunkDispatch<unknown, unknown, AnyAction>;

axios.interceptors.request.use((request) => {
  const token = SessionStorage.get(SessionStorage.token);
  if (token && request.headers) {
    request.headers.Authorization = `Bearer ${token}`;
  }
  return request;
});

export function setResponseInterceptor(
  appDispatch: ThunkDispatch<unknown, unknown, AnyAction>
) {
  dispatch = appDispatch;
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      dispatch(setApiError(Errors.getErrorDtoFromApiError(error)));
      return Promise.reject(error);
    }
  );
}

export default axios;
